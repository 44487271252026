
  import { Component, Vue } from "vue-property-decorator";

  import { loadStripe } from "@stripe/stripe-js";
  import { api } from "@/api";
  import { readToken } from "@/store/main/getters";
  import { dispatchCheckApiError } from "@/store/main/actions";
  import { IThinkificProduct, IQuestionDetail, IAnswer } from "@/interfaces";
  import { stripePublicKey } from "@/env";

  import ProductCheckout from "@/components/ProductCheckout.vue";
  import RegistrationQuestions from "@/components/RegistrationQuestions.vue";

  const stripePromise = loadStripe(stripePublicKey);

  @Component({
    components: {
      ProductCheckout,
      RegistrationQuestions,
    },
  })
  export default class CheckoutRecertification extends Vue {
    course: IThinkificProduct | null = null;
    loadingQuestions = true;
    questions: IQuestionDetail[] = [];
    questionsAndAnswers: { question: IQuestionDetail; answer: string }[] = [];

    async redirectToStripeCheckout(stripeCheckoutSessionId: string) {
      const stripe = await stripePromise;
      if (stripe) {
        await stripe.redirectToCheckout({
          sessionId: stripeCheckoutSessionId,
        });
      }
    }

    async getQuestions() {
      const token = readToken(this.$store);
      try {
        this.loadingQuestions = true;
        const resp = await api.getRegistrationQuestions(token);
        this.questions = resp.data;
        this.loadingQuestions = false;
      } catch (error) {
        this.loadingQuestions = false;
        dispatchCheckApiError(this.$store, error);
      }
    }

    public async mounted() {
      const token = readToken(this.$store);
      try {
        const resp = await api.getRecertCourse(token);
        this.course = resp.data;
      } catch (error) {
        this.course = null;
        dispatchCheckApiError(this.$store, error);
      }
      this.getQuestions();
    }

    async onSubmit(answersIn: { question: IQuestionDetail; answer: string }[]) {
      const token = readToken(this.$store);
      try {
        const answers: IAnswer[] = answersIn.map((q) => ({
          question_id: q.question.id.toString(),
          answer_text: Array.isArray(q.answer) ? q.answer.join(", ") : q.answer,
        }));
        const resp = await api.createRecertCourseCheckoutSession(token, answers);
        if (resp.data.stripe_checkout_session_id) {
          await this.redirectToStripeCheckout(resp.data.stripe_checkout_session_id);
        }
      } catch (error) {
        dispatchCheckApiError(this.$store, error);
      }
    }
  }
